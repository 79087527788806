import { useState, useEffect, useCallback } from "react"
import {Row, Col, Button, Icon, Pagination, CheckPicker, DateRangePicker, TagPicker, Badge, SelectPicker, Input, InputNumber, Panel, Modal, Tag, Message,Notification, Uploader, DatePicker, Timeline} from "rsuite"


import format from "date-fns/format"

import Card from '../../components/Card.component'
import GenericDrawer from '../../components/GenericDrawer.component'
import parseDateTime from "../../utils/parseDateTime"

import LoadingTable from "../../components/LoadingTable.component"
import ReportService from "../../services/Report.service"
import MembersAreaService from "../../services/MembersArea.service"
import telefoneIcon from "../../assets/img/whatsapp-phone-icon.png"
import GenericModal from "../../components/GenericModal.component"
import { addDays } from 'date-fns';


const StudentsPage = (props) => {

    const getActionMessage = (action) => {
        switch (action) {
            case 'Update':
                return 'Editado por';
            case 'Created':
                return 'Criado por';
            case 'Deleted':
                return 'Deletado por';
            default:
                return 'Ação desconhecida';
        }
    };

    
    const defaultList = {
        count: 0,
        sum: 0,
        pages: 0,
        data:[]
    }
    const [studentList, setStudentList] = useState(defaultList)

    const defaultDrawerState = {
        filter: {open: false, data: {
            // date_range: [
                // format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                // format(new Date(), 'yyyy-MM-dd  hh:mm:ss')
            // ]
        }},
        order: {open: false, data: {}}
    }

    const defaultStudentState = {
        student_name: "", 
        student_email: "", 
        student_taxid: "", 
        school: "", 
        course: "",
        class: ""
    }

    const defaultStudentListState = { 
        school: "", 
        course: "",
        class: ""
    }

    const [drawerState, setDrawerState] = useState(defaultDrawerState)
    const [loading, setLoading] = useState(true)
    const [loadingSchool, setLoadingSchool] = useState(true)
    const [modalInterface, setModalInterface] = useState(false)
    const [modalAddStudent, setModalAddStudent] = useState(false)
    const [studentState, setStudentState] = useState(defaultStudentState)
    const [studentListState, setStudentListState] = useState(defaultStudentListState)

    const openDrawer = (drawerName, data = {}) => setDrawerState({...drawerState, [drawerName]: {open: true, data: {...drawerState[drawerName].data, ...data}}});

    const clearDrawerData = (drawerName) => setDrawerState({...drawerState, [drawerName]: {open: false, data: {}}});

    const closeDrawer = (drawerName) => setDrawerState({...drawerState, [drawerName]: {open: false, data: {...drawerState[drawerName]?.data}}});

    const handleDrawerChange = async (field, value) => {
        const payload = {...drawerState, filter: {...drawerState.filter, data: {...drawerState.filter.data, [field]: value}}}
        setDrawerState(payload)
    }

    const handleStudentState = async (field, value) => {
        const updatedState = {
            ...studentState,
            [field]: value,
        };
        setStudentState(updatedState)
    }

    const handleStudentListState = async (field, value) => {
        const updatedState = {
            ...studentListState,
            [field]: value,
        };
        setStudentListState(updatedState)
    }

    const isFormValid = () => {
        const { student_name, student_email, student_taxid, school, course, typeOfAccess } = studentState;
    
        const isValidCPF = (cpf) => /^\d{11}$/.test(cpf);
    
        const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    
        return (
            Boolean(student_name) &&
            isValidEmail(student_email) &&
            isValidCPF(student_taxid) &&
            Boolean(school?.length) &&
            Boolean(course?.length)&&
            Boolean(typeOfAccess?.length)
        );
    };

    const isFormListValid = () => {
        const { school, course } = studentListState;
    
        return (
            Boolean(school) &&
            Boolean(course)
        );
    };

    const [activePage, setActivePage] = useState(1)

    const getStudentList = async (page = 1, filters = {}) => {
        setStudentList(defaultList)
        setLoading(true)
        filters = {...filters}//, adminMode: props.isAdmin ? true : false
        setActivePage(page)
        const response = await new MembersAreaService().post(filters,`students/list/${page}`)
        console.log(response)
        setStudentList(response)
        setLoading(false)
    }

    const navigateToPage = (number) => {
        setStudentList({...studentList, data:[]})
        getStudentList(number, drawerState.filter.data ? drawerState.filter.data : {})
    }

    useEffect(() => {
        getStudentList()
        getSchoolWithCourseList()
    }, [])

    const [schoolList, setSchoolList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [classList, setClassList] = useState([]);

    const getSchoolWithCourseList = async () => {
        const responseSchool = await new MembersAreaService().get('school/simple/') || []
        setSchoolList(responseSchool)
        let courses = []
        responseSchool.map(school => {
            school.courses.map(course => {
                if(!courses.find(_course => _course._id === course._id)){
                    courses.push(course)
                }
            })
        })
        setCourseList(courses)
        const responseClass = await new MembersAreaService().get('class/simple/') || []

        const formattedClassList = responseClass.map(classItem => ({
            ...classItem,
            displayName: `${classItem.name} - ${classItem.classCode}`
        }));

        setClassList(formattedClassList)
    }

    const getStatus = (status) => {
        switch(status){
            case true:
                return <Button className="payment-status-approved"> <span>Ativo</span></Button>
            case false:
                return <Button className="payment-status-denied"> <span>Inativo</span></Button>
            default:
                return  <Button className="payment-status-denied"> <span>Inativo</span></Button>
        }
    }

    const [tab, setTab] = useState(1);
    const [tabModal, setTabModal] = useState(1);

    const handleTabClick = useCallback((tabNumber) => {
        if(tabNumber === 2){
            getSchoolPaginatedList()
        }
        setTab(tabNumber)
        clearDrawerData('filter')
    }, []);

    const handleTabModalClick = useCallback((tabNumber) => {
        setTabModal(tabNumber)
    }, []);

    const [classPaginatedList, setClassPaginatedList] = useState({defaultList})

    const getSchoolPaginatedList = async (page = 1, filters = {}) => {
        setClassPaginatedList(defaultList)
        setLoadingSchool(true)
        filters = {...filters}
        setActivePage(page)
        const response = await new MembersAreaService().post(filters,`list/school/class/${page}`)
        setClassPaginatedList(response)
        setLoadingSchool(false)
    }

    const navigateToPageSchool = (number) => getSchoolPaginatedList(number, {})

    const [canGetCertificate, setCanGetCertificate] = useState({})

    const verifyIfCanGetCertificate = async (schoolId, courseId, userId) => {
        const key = `${schoolId}-${courseId}-${userId}`;
   
        if (!canGetCertificate.hasOwnProperty(key)) {
            try {
                const response = await new MembersAreaService().get(
                    `certificate/verify/${schoolId}/${courseId}?user_id=${userId}`,
                    {silent: true}
                )
                
                setCanGetCertificate(prevState => ({ ...prevState, [key]: response.result }))
            } catch (error) {
                console.error('Error fetching certificate info:', error);
                setCanGetCertificate(prevState => ({ ...prevState, [key]: false })); 
            }
        }
    };

    const [userModal, setUserModal] = useState({})
    const [classModal, setClassModal] = useState({})
    const [updateClassModal, setUpdateClassModal] = useState({})
    const [classListModal, setClassListModal] = useState([])
    const [removeClassModal, setRemoveClassModal] = useState(true)
    const [progressModal, setProgressModal] = useState({})
    const [exerciceSessions, setExerciceSessions] = useState({})
    const [questions, setQuestions] = useState([])
    const [commentsModal, setCommentsModal] = useState({
        course: {},
        modules: []
    })

    const openInterfaceModal = async (user, studentClass, progress, classCode, schoolId, classId) => {
        const response = await new MembersAreaService().get(`lesson/comments/simple/${user.student._id}/${studentClass}`)
        setCommentsModal(response)
        setProgressModal(progress)
        setUserModal(user)
        if(classCode){
            setRemoveClassModal(false)
            setClassModal(classId)
        }
        const responseClass = await new MembersAreaService().get(`class/simple/modal/${schoolId}`) || []
        
        const formattedClassList = responseClass.map(classItem => ({
            ...classItem,
            displayName: `${classItem.name} - ${classItem.classCode}`
        }));

        setClassListModal(formattedClassList)
        setModalInterface(true)
    }

    const closeInterfaceModal = () => {
        setModalInterface(false)
        setUserModal({})
        setClassModal({})
        setRemoveClassModal(true)
        setTabModal(1)
    }

    const closeModalAddStudent = () => {
        setModalAddStudent(false)
        setStudentState(defaultStudentState)
    }

    const loadExerciceSessions = async (lessonId, userId) => {
        const response = await new MembersAreaService().get(`management/student/exercices/sessions/${lessonId}/${userId}`);
        setExerciceSessions(prevSessions => ({
            ...prevSessions,
            [lessonId]: response
        }));
    }

    const loadQuestions = async ( exerciceId, sortQuestions, sortAnswers ) => {
        let {questions} = await new MembersAreaService().get(`student/exercice/questions/${exerciceId}`)

        if(sortQuestions){
            questions = questions?.sort(function(a, b){return 0.5 - Math.random()})
        }

        if(sortAnswers){
            questions = questions.map(question => {
                question.answers?.sort(function(a, b){return 0.5 - Math.random()})
                return question
            })
        }
        setQuestions(questions || [])
    }

    
    const statusExercice = (status) => {
        switch(status){
            case "pending":
                return <Tag color="orange" size={'xs'}><span>Em andamento</span></Tag>
            case "finished":
                return <Tag color="green" size={'xs'}><span>Finalizado</span></Tag>
            case "expired":
                return <Tag color="red" size={'xs'}><span>Expirado</span></Tag>
            default: 
                return <Tag color="yellow" size={'xs'}><span>Pendente</span></Tag>
        }

    }
    const [reportModalState, setReportModalState] = useState({
        open: false,
    })

    const openReportModal = (payload) => {
        setReportModalState({open: true})
    }
    const closeReportModal = () => {
        setReportModalState({open: false})
    }


    const requestReport = async () => {
        const response = await new ReportService().post({
            reportName: 'students',
            filters: drawerState.filter.data
        },'request')

        if(response.event){
            Notification['success']({
                title: 'Relatório solicitado',
                description: 'Em alguns minutos você será notificado por e-mail com o download do seu relatório ou acessando o menu Relatórios'
            });
        }
    }

    const [modalRemoveState, setModalRemoveState] = useState(false);
    const [removeStudentModal, setRemoveStudentModal] = useState({})
    const [removeClassIdtModal, setRemoveClassIdtModal] = useState({})
    const [removeCourseIdtModal, setRemoveCourseIdtModal] = useState({})
    const [removeStateAllCourse, setRemoveStateAllCourse] = useState(false);

    const openRemoveModal = (student, classId, allCourse, courseId ) => {
        setRemoveStudentModal(student)
        setRemoveClassIdtModal(classId)
        setRemoveStateAllCourse(allCourse)
        setRemoveCourseIdtModal(courseId)
        setModalRemoveState(true)
    }

    const closeRemoveModal = () => {
        setModalRemoveState(false)
    }

    const handleRemoveStudentClass = async (studentId, classId, allCourse, courseId) => {
        try {
            const payload = {
                studentId: studentId,
                classId: classId,
                allCourse: allCourse,
                courseId: courseId
            }
            const response = await new MembersAreaService().put(payload,`remove/student/class`)
            if (response.success) {
                setModalRemoveState(false)
                closeInterfaceModal()
                getStudentList()
                if(tab === 2){
                    handleTabClick(2)
                }
            } else {
                console.error("Erro ao deletar a notificação:", response.error);
            }
        } catch (error) {
            console.error("Erro ao deletar a notificação:", error);
        }
    }
    //leo
    const handleUpdateStudentClass = async (studentId, classId, courseId) => {
        try {
            const payload = {
                studentId: studentId,
                classId: classId,
                courseId: courseId
            }
            const response = await new MembersAreaService().put(payload,`update/student/class`)
            if (response.success) {
                closeInterfaceModal()
                getStudentList()
            ;
            } else {
                console.error("Erro ao deletar a notificação:", response.error);
            }
        } catch (error) {
            console.error("Erro ao deletar a notificação:", error);
        }
    }

    const addStudent = async () => {
        const response = await new MembersAreaService().post(studentState, 'add/student')

        if(response.result){
            Notification['success']({
                title: 'Aluno Adicionado',
                description: 'Em alguns minutos o aluno será notificado por e-mail'
            });
            setStudentState(defaultStudentState)
        }
    }

    const modelSheetLink = "https://docs.google.com/spreadsheets/d/1QKH42h0BKxUlAaWOqn-z2xhcVnFdGU2POmmojUe-C74/edit?gid=0#gid=0";

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const setEndOfDay = (date) => {
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay;
    };

    const accessList = [
        {value: "full_access", label: 'Acesso Completo' },
        {value: "first_lesson_only", label: 'Acesso à Primeira Aula' }
    ]
                
    useEffect(() => {
        if (modalInterface && commentsModal?.modules?.length > 0) {
            commentsModal?.modules?.forEach(module => {
                module?.lessons?.forEach(lesson => {
                    if (lesson.type === 'exercice') {
                        loadExerciceSessions(lesson._id, userModal?.student?._id);
                        loadQuestions(lesson._id, lesson.sortQuestions, lesson.sortAnswers );
                    }
                });
            });
        }
    }, [modalInterface, commentsModal, userModal]);

    useEffect(() => {
        studentList?.data?.forEach(studentObj => {
            studentObj?.courses?.forEach(courseObj => {
                const key = `${courseObj?.school._id}-${courseObj?._id}-${studentObj?.student?._id}`;
                if (!canGetCertificate.hasOwnProperty(key)) {
                    verifyIfCanGetCertificate(courseObj?.school._id, courseObj?._id, studentObj?.student?._id);
                }
            });
        });
    }, [studentList]);

    return <>
        <Row>
            <Col md={12}>
                <h3> Alunos</h3>
            </Col>
            <Col md={12} style={{display: 'flex', justifyContent: 'end'}}>
                <Row style={{marginRight: '15px'}}>
                    <Badge content={Object.values(drawerState.filter.data).filter(obj => obj !== "").length ? Object.values(drawerState.filter.data).filter(obj => obj !== "").length : false }>
                        <Button appearance="ghost" color="green" onClick={() => openReportModal()}><Icon icon="flag" /> Solicitar relatório</Button>
                    </Badge>
                </Row>
                <Row style={{marginRight: '15px'}}>
                    <Badge content={Object.values(drawerState.filter.data).filter(obj => obj !== "").length ? Object.values(drawerState.filter.data).filter(obj => obj !== "").length : false }>
                        <Button appearance="primary" onClick={() => openDrawer('filter')}><Icon icon="sliders" /> Filtros</Button>
                    </Badge>
                </Row>
                <Row style={{marginRight: '3px'}}>
                    <Button appearance={'primary'} onClick={() => setModalAddStudent(true)}>Adicionar Aluno</Button>
                </Row>   
            </Col>
        </Row>
        <Row className="only-desk">
            <Row style={{margin: '20px 5px', padding: '15px 5px', background: 'rgba(255, 255, 255, 0.6)', display: 'flex', flexDirection: 'row'}}>
                <Row style={{width: '100%'}}>
                    <Button appearance={tab === 1 ? 'primary' : 'subtle'} onClick={() => handleTabClick(1)}>Todos Alunos</Button>
                    <Button appearance={tab === 2 ? 'primary' : 'subtle'} onClick={() => handleTabClick(2)}>Alunos por Turma</Button>
                </Row>
            </Row>
        </Row>

        {
            tab === 1 ? <>
                <Row>
                    <Col lg={12}>
                        <Card style={{borderColor: '#d2d6dc'}}>
                            <Row>
                                <Col style={{color: 'rgba(0,0,0,0.5)'}} lg={24}>Total de alunos</Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <h3>
                                        {studentList.students === undefined ? '-' : studentList.students}
                                    </h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Card style={{borderColor: '#d2d6dc'}}>
                            <Row>
                                <Col style={{color: 'rgba(0,0,0,0.5)'}} lg={24}>Cursos concluidos</Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <h3>
                                        {studentList.completedCourses === undefined ? '-' : studentList.completedCourses}
                                    </h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                
                <Row className="table-container" style={{marginTop: '25px'}}>
                    <Row className="table">
                        {
                            studentList?.data?.length && !loading? <Row style={{display: 'flex', flexDirection: 'row'}} className="list-responsive">
                                <Row className="rs-col-md-6 rs-col-xs-6">NOME DO ALUNO</Row>
                                <Row className="rs-col-md-8 rs-col-xs-8">E-MAIL</Row>
                                <Row className="rs-col-md-3 rs-col-xs-3">DATA DE ENTRADA</Row>
                                <Row className="rs-col-md-2 rs-col-xs-2"></Row>
                                <Row className="rs-col-md-4 rs-col-xs-4">STATUS</Row>
                            </Row> : null
                        }

                        {   
                            !studentList?.data?.length && !loading ? <>
                                <Row style={{marginTop: '25px'}}>
                                    <Col lg={24}>
                                        <Card>
                                            <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhum aluno encontrado</h5>
                                        </Card>
                                    </Col>
                                </Row>
                            </> : null
                        }

                        {
                            !studentList?.data?.length && loading ? <LoadingTable /> : null
                        }

                        {
                            studentList?.data?.map((studentObj) => {
                                return   <Panel style={{background: 'white', marginBottom: '.7em'}} header={<Row style={{fontSize: '0.9em', display: 'flex', flexDirection: 'row'}}>
                                <Row className="rs-col-md-6 rs-col-xs-6">
                                    {studentObj?.student?.name}
                                </Row>
                                <Row className="rs-col-md-8 rs-col-xs-8">
                                    {studentObj?.student?.email}
                                </Row>
                                <Row className="rs-col-md-3 rs-col-xs-3">
                                    {studentObj?.courses[0]?.createdAt ? parseDateTime(studentObj.courses[0].createdAt) : 'Sem data'}
                                </Row>
                                <Row className="rs-col-md-2 rs-col-xs-2"></Row>
                                <Row className="rs-col-md-4 rs-col-xs-4">
                                    {getStatus(studentObj.active)}
                                </Row>
                            </Row>} collapsible>
                                <Row>
                                    <Col lg={24}><h6>Dados do Aluno</h6></Col>
                                </Row>
                                <Row style={{marginTop: '10px'}}>
                                    <Col lg={24}>
                                        <strong>Email:</strong> {studentObj?.student?.email}
                                        <strong style={{marginLeft: '15px'}}>CPF:</strong> {studentObj?.student?.taxid}
                                        <strong style={{marginLeft: '15px'}}>Telefone:</strong> {studentObj?.student?.phoneNumber.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")} <a
                                            href={`https://api.whatsapp.com/send?phone=${studentObj?.student?.phoneNumber}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ marginRight: '8px', cursor: 'pointer' }}
                                            >
                                            <img src={telefoneIcon} 
                                            alt="Ícone de telefone" 
                                            style={{ marginRight: '8px',width: '20px', height: '20px' }} 
                                            />
                                            </a>
                                        <strong style={{marginLeft: '15px'}}>Conta ativa:</strong> {studentObj?.student?.isRegistrationComplete ? 'Sim' : 'Não'}
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '10px'}}>
                                    <Col lg={24}>
                                        <strong>Endereço:</strong> {studentObj?.student?.address?.addressName}, Nº {studentObj?.student?.address?.addressNumber}, {studentObj?.student?.address?.addressNeighborhood}, {studentObj?.student?.address?.city} -  {studentObj?.student?.address?.addressState}
                                        <strong style={{marginLeft: '15px'}}>Complemento:</strong> {studentObj?.student?.address?.addressComplement}
                                        <strong style={{marginLeft: '15px'}}>CEP:</strong> {studentObj?.student?.address?.zipCode}
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row style={{marginTop: '10px'}}>
                                    <Col lg={24}><h6>Histórico de Observações</h6></Col>
                                    <Col lg={24} style={{marginTop: '10px'}}>
                                        <Timeline>
                                            {studentObj?.auditLogs?.length === 0 ? (
                                                    <Timeline.Item>
                                                        {studentObj?.order ? `Aluno criado na compra (${studentObj.order})` : "Nenhuma observação disponível"}
                                                    </Timeline.Item>
                                                ) : (
                                                    studentObj?.auditLogs?.map((item, index) => (
                                                        <Timeline.Item key={index}>
                                                            {parseDateTime(item.timestamp)} - {getActionMessage(item.action)} {item?.user?.name}
                                                        </Timeline.Item>
                                                    ))
                                                )}
                                        </Timeline>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row style={{marginTop: '25px'}}>
                                    <Col lg={24}><h6>Cursos</h6></Col>
                                </Row>

                                <Row className="table-container">
                                    <Row className="table-webhook">
                                        <Row style={{marginTop:"25px", background:"#d2d6dc30",display: 'flex', flexDirection: 'row', padding:"10px 15px"}} className="list-responsive">
                                            <Row className="rs-col-md-6 rs-col-xs-6">
                                                CURSO
                                            </Row>
                                            <Row className="rs-col-md-4 rs-col-xs-4">
                                                ENTRADA
                                            </Row>
                                            <Row className="rs-col-md-7 rs-col-xs-7">
                                                PROGRESSO
                                            </Row>
                                            <Row className="rs-col-md-3 rs-col-xs-3">
                                                ESCOLA
                                            </Row>
                                            <Row className="rs-col-md-5 rs-col-xs-5">
                                                TURMA
                                            </Row>
                                            <Row className="rs-col-md-4 rs-col-xs-4"></Row>
                                            <Row className="rs-col-md-4 rs-col-xs-4"></Row>
                                        </Row>
                                    </Row>
                                </Row>
                                {
                                    studentObj?.courses?.map(courseObj => {
                                        const key = `${courseObj?.school._id}-${courseObj?._id}-${studentObj?.student?._id}`;
                                        
                                        const isEnabled = canGetCertificate[key];

                                        return (
                                            <Row key={key} style={{marginTop:"25px",display: 'flex', flexDirection: 'row', padding:"10px 15px", alignItems: 'center'}} className="list-responsive">

                                                <Row className="rs-col-md-6 rs-col-xs-6">
                                                    {courseObj?.name}
                                                </Row>
                                                <Row className="rs-col-md-4 rs-col-xs-4">
                                                    {parseDateTime(courseObj?.createdAt)}
                                                </Row>
                                                <Row className="rs-col-md-7 rs-col-xs-7">
                                                    <div style={{ minWidth: '3%', width: '90%', left: '5px', bottom: '0px', display: 'flex', alignItems: 'center'}}>
                                                        <div className="progress-bar" style={{minWidth: '4%', width: `${courseObj?.progress?.courseDonePercent}%`, height: '5px', borderRadius: '3px'}}></div> 
                                                        <span style={{fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>
                                                            {courseObj?.progress?.courseDonePercent % 1 === 0 ? courseObj?.progress?.courseDonePercent?.toFixed(0) : parseFloat(courseObj?.progress?.courseDonePercent).toFixed(1)}%
                                                        </span>
                                                    </div>
                                                </Row>
                                                <Row className="rs-col-md-3 rs-col-xs-3">
                                                    {courseObj?.school?.name}
                                                </Row>
                                                <Row className="rs-col-md-5 rs-col-xs-5">
                                                    {courseObj?.class?.classCode || "-"}
                                                </Row>
                                                <Row className="rs-col-md-4 rs-col-xs-4">
                                                    <Col lg={24}> 
                                                        <Button 
                                                            disabled={!isEnabled} 
                                                            onClick={() => {
                                                                window.open(`${process.env.REACT_APP_API_URL}/members/certificate/generate/${courseObj?.school._id}/${courseObj?._id}/${studentObj?.student?._id}`, '_blank')
                                                            }} 
                                                            appearance='ghost'
                                                        >
                                                            <Icon icon="mortar-board" /> Certificado
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row  className="rs-col-md-4 rs-col-xs-4">
                                                    <Col lg={24}> 
                                                        <Button 
                                                            onClick={() => 
                                                                openInterfaceModal(
                                                                    studentObj, 
                                                                    courseObj?._id, 
                                                                    courseObj?.progress,
                                                                    courseObj?.class?.classCode ? true : false,
                                                                    courseObj?.school?._id,
                                                                    courseObj?.class?._id
                                                                ) 
                                                            } 
                                                            appearance='ghost'
                                                        >
                                                            <Icon icon="edit"/> Gestão
                                                        </Button>
                                                    </Col>    
                                                </Row>
                                                {/* <Row  className="rs-col-md-4 rs-col-xs-4">
                                                    <Col lg={24}>
                                                        <Button 
                                                            color="green" 
                                                            appearance='ghost' 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleUpdateStudentClass(studentObj?.student?._id, courseObj?.class?._id, courseObj?._id)
                                                            }} 
                                                        >
                                                            {<Icon icon="close-circle" style={{marginRight: '5px'}} />}
                                                            {`add aluno`}
                                                        </Button>
                                                    </Col>
                                                </Row> */}
                                            </Row>
                                        )
                                    })
                                }
                                
                            </Panel>
                            })
                        }
                    </Row>
                </Row>
                
                <Row style={{marginTop: '15px'}}>
                    <Col lg={24} style={{display: 'flex', justifyContent: 'end'}}>
                        <Pagination
                            size="lg"
                            prev={true}
                            next={true}
                            first={true}
                            last={true}
                            ellipsis={true}
                            boundaryLinks={true}
                            pages={studentList?.pages}
                            maxButtons={5}
                            activePage={activePage}
                            onSelect={navigateToPage}
                        />
                    </Col>
                </Row>
            </> : null
        }

        {
            tab === 2 ? <>
                <Row>
                    <Col lg={12}>
                        <Card style={{borderColor: '#d2d6dc'}}>
                            <Row>
                                <Col style={{color: 'rgba(0,0,0,0.5)'}} lg={24}>Total de turmas</Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <h3>
                                        {classPaginatedList.class === undefined ? '-' : classPaginatedList.class}
                                    </h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Card style={{borderColor: '#d2d6dc'}}>
                            <Row>
                                <Col style={{color: 'rgba(0,0,0,0.5)'}} lg={24}>Cursos concluidos</Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <h3>
                                        {classPaginatedList.completedCourses === undefined ? '-' : classPaginatedList.completedCourses}
                                    </h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row className="table-container" style={{marginTop: '25px'}}>
                    <Row className="table">
                        {
                            classPaginatedList?.data?.length && !loadingSchool ? <Row style={{display: 'flex', flexDirection: 'row'}} className="list-responsive">
                                <Row className="rs-col-md-8 rs-col-xs-8">NOME DA TURMA</Row>
                                <Row className="rs-col-md-8 rs-col-xs-8">CÓDIGO DA TURMA</Row>
                                <Row className="rs-col-md-3 rs-col-xs-3">ESCOLA</Row>
                                <Row className="rs-col-md-2 rs-col-xs-2"></Row>
                                <Row className="rs-col-md-4 rs-col-xs-4"></Row>
                            </Row> : null
                        }

                        {
                            !classPaginatedList?.data?.length && loadingSchool? <LoadingTable /> : null
                        }


                        {   
                            !classPaginatedList?.data?.length && !loadingSchool ? <>
                                <Row style={{marginTop: '25px'}}>
                                    <Col lg={24}>
                                        <Card>
                                            <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhuma turma encontrada</h5>
                                        </Card>
                                    </Col>
                                </Row>
                            </> : null
                        }

                        {
                            classPaginatedList?.data?.map((studentClass) => {
                                return <>
                                    <Panel style={{background: 'white', marginBottom: '.7em'}} header={<Row style={{fontSize: '0.9em', display: 'flex', flexDirection: 'row'}}>
                                            <Row className="rs-col-md-8 rs-col-xs-8">{studentClass.name}</Row>
                                            <Row className="rs-col-md-8 rs-col-xs-8">{studentClass.classCode}</Row>
                                            <Row className="rs-col-md-8 rs-col-xs-8">{studentClass.school.name}</Row>
                                    </Row>} collapsible> 
                                    <Row>
                                        <Col lg={24}><h6>Dados do Turma</h6></Col>
                                    </Row>
                                    <Row style={{marginTop: '10px'}}>
                                        <Col lg={24}>
                                            <strong>Data de inicio:</strong> {studentClass?.startDate ? parseDateTime(studentClass?.startDate) : 'Sem Data'}
                                            
                                            <strong style={{marginLeft: '15px'}}>Data do fim:</strong> {studentClass?.endDate ? parseDateTime(studentClass?.endDate) : 'Sem Data'}
                                        </Col>
                                    </Row>
                                        <Row style={{marginTop: '15px'}}>
                                            <Col lg={24}><h6>ALUNOS</h6></Col>
                                        </Row>
                                        {
                                            studentClass.studentCourses?.length != 0 ? <>
                                                <Row style={{display: 'flex', flexDirection: 'row', paddingBottom: '10px'}} className="list-responsive">
                                                    <Col className="rs-col-md-8 rs-col-xs-8">NOME DO ALUNO</Col>
                                                    <Col className="rs-col-md-6 rs-col-xs-6">E-MAIL</Col>
                                                    <Col className="rs-col-md-5 rs-col-xs-5">DATA DE ENTRADA</Col>
                                                    <Col className="rs-col-md-5 rs-col-xs-5"></Col>
                                                </Row>
                                                     {studentClass.studentCourses?.map((studentCourse) => (
                                                        <Panel 
                                                        
                                                        style={{ background: "#d2d6dc30", paddingBottom: '10px', marginBottom: '.7em'}}
                                                        header={
                                                            <Row key={studentCourse._id} style={{fontSize: '0.9em', display: 'flex', alignItems: 'center' }}>
                                                                <Col className="rs-col-md-8 rs-col-xs-8">
                                                                    {studentCourse?.student?.name}
                                                                </Col>
                                                                <Col className="rs-col-md-6 rs-col-xs-6">
                                                                    {studentCourse?.student?.email}
                                                                </Col>
                                                                <Col className="rs-col-md-5 rs-col-xs-5">
                                                                    {studentCourse.createdAt ? parseDateTime(studentCourse?.createdAt) : '-'}
                                                                </Col>
                                                                <Col className="rs-col-md-5 rs-col-xs-5">
                                                                    <Button 
                                                                        color="red" 
                                                                        appearance='ghost' 
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            openRemoveModal(studentCourse?.student,studentClass._id, true );
                                                                        }} 
                                                                    >
                                                                        {<Icon icon="minus-square" style={{marginRight: '5px'}} />}
                                                                        {`Remover aluno`}
                                                                    </Button>
                                                                </Col>
                                                            </Row>}
                                                        collapsible>
                                                            <Row>
                                                                <Col lg={24}><h6>Dados do Aluno</h6></Col>
                                                            </Row>
                                                            <Row style={{marginTop: '10px'}}>
                                                                <Col  lg={24}>
                                                                    <strong>Email:</strong> {studentCourse?.student?.email}
                                                                    <strong style={{marginLeft: '15px'}}>CPF:</strong> {studentCourse?.student?.taxid}
                                                                    <strong style={{marginLeft: '15px'}}>Telefone:</strong> {studentCourse?.student?.phoneNumber.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")} <a
                                                                        href={`https://api.whatsapp.com/send?phone=${studentCourse?.student?.phoneNumber}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                        >
                                                                        <img src={telefoneIcon} 
                                                                        alt="Ícone de telefone" 
                                                                        style={{ marginRight: '8px',width: '20px', height: '20px' }} 
                                                                        />
                                                                        </a>
                                                                    <strong style={{marginLeft: '15px'}}>Conta ativa:</strong> {studentCourse?.student?.isRegistrationComplete ? 'Sim' : 'Não'}
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginTop: '10px'}}>
                                                                <Col lg={24}>
                                                                    <strong>Endereço:</strong> {studentCourse?.student?.address?.addressName}, Nº {studentCourse?.student?.address?.addressNumber}, {studentCourse?.student?.address?.addressNeighborhood}, {studentCourse?.student?.address?.city} -  {studentCourse?.student?.address?.addressState}
                                                                    <strong style={{marginLeft: '15px'}}>Complemento:</strong> {studentCourse?.student?.address?.addressComplement}
                                                                    <strong style={{marginLeft: '15px'}}>CEP:</strong> {studentCourse?.student?.address?.zipCode}
                                                                </Col>   
                                                            </Row>
                                                            <hr></hr>
                                                            <Row style={{marginTop: '25px'}}>
                                                                <Col lg={24}><h6>Cursos</h6></Col>
                                                            </Row>
                                                            <Row 
                                                                style={{marginTop:"25px", background:'white',display: 'flex', flexDirection: 'row', padding:"10px 15px"}} 
                                                                className="list-responsive"
                                                            >
                                                                <Row className="rs-col-md-8 rs-col-xs-8">
                                                                    CURSO
                                                                </Row>
                                                                <Row className="rs-col-md-5 rs-col-xs-5">
                                                                    ENTRADA
                                                                </Row>
                                                                <Row className="rs-col-md-9 rs-col-xs-9">
                                                                    PROGRESSO
                                                                </Row>
                                                                <Row className="rs-col-md-5 rs-col-xs-5">
                                                                </Row>
                                                                <Row className="rs-col-md-5 rs-col-xs-5">
                                                                </Row>
                                                            </Row>
                                                            {
                                                                studentCourse?.student?.courses?.map(courseObj => {
                                                                    const key = `${studentClass.school._id}-${courseObj?._id}-${studentCourse?.student?._id}`;
                                                                    
                                                                    const isEnabled = canGetCertificate[key];
                                                                    
                                                                    return <Row style={{marginTop:"25px",display: 'flex', alignItems: 'center', flexDirection: 'row', padding:"10px 15px"}} className="list-responsive">

                                                                    <Row className="rs-col-md-8 rs-col-xs-8">
                                                                        {courseObj?.name}
                                                                    </Row>
                                                                    <Row className="rs-col-md-5 rs-col-xs-5">
                                                                        {parseDateTime(courseObj?.createdAt)}
                                                                    </Row>
                                                                    <Row className="rs-col-md-9 rs-col-xs-9">
                                                                        <div style={{minWidth: '3%', width: '90%', left: '5px', bottom: '0px', display: 'flex', alignItems: 'center'}}>
                                                                            <div className="progress-bar" style={{minWidth: '4%', width: `${courseObj?.progress?.courseDonePercent}%`, height: '5px', borderRadius: '3px'}}></div> 
                                                                            <span style={{fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>
                                                                                {courseObj?.progress?.courseDonePercent % 1 === 0 ? courseObj?.progress?.courseDonePercent?.toFixed(0) : parseFloat(courseObj?.progress?.courseDonePercent).toFixed(1)}%
                                                                            </span>
                                                                        </div>
                                                                    </Row>
                                                                    
                                                                    <Row className="rs-col-md-5 rs-col-xs-5">
                                                                        <Col lg={24}> 
                                                                            <Button 
                                                                                disabled={!isEnabled} 
                                                                                onClick={() => {
                                                                                    window.open(`${process.env.REACT_APP_API_URL}/members/certificate/generate/${courseObj?.school._id}/${courseObj?._id}/${studentCourse?.student?._id}`, '_blank')
                                                                                }} 
                                                                                appearance='ghost'
                                                                            >
                                                                                <Icon icon="mortar-board" /> Certificado
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                    <Row  className="rs-col-md-5 rs-col-xs-5">
                                                                        <Col lg={24}> 
                                                                            <Button 
                                                                                onClick={() => 
                                                                                    openInterfaceModal(
                                                                                        studentCourse, 
                                                                                        courseObj?._id, 
                                                                                        courseObj?.progress,
                                                                                        true,
                                                                                        courseObj?.school?._id,
                                                                                        studentClass._id
                                                                                    )}
                                                                                appearance='ghost'
                                                                            >
                                                                                <Icon icon="edit"/> Gestão
                                                                            </Button>
                                                                        </Col>    
                                                                    </Row>    
                                                                
                                                                </Row>
                                                                })
                                                            }
                                                        </Panel>
                                                    ))}
                                                
                                            </> :
                                            <Row style={{marginTop: '25px'}}>
                                                <Col lg={24}>
                                                    <Card>
                                                        <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Não contem alunos</h5>
                                                    </Card>
                                                </Col>
                                            </Row>
                                                
                                        }
                                    </Panel>
                                    
                                </>
                            })
                        }
                    </Row>
                </Row>

                <Row style={{marginTop: '15px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Pagination
                        size="lg"
                        prev={true}
                        next={true}
                        first={true}
                        last={true}
                        ellipsis={true}
                        boundaryLinks={true}
                        pages={classPaginatedList.pages}
                        maxButtons={5}
                        activePage={activePage}
                        onSelect={navigateToPageSchool}
                    />
                </Row>

            </> : null
        }
        {
            tab === 3 ? <>
                <Row style={{marginTop: '25px'}}>
                    <Col lg="8" xs="24">
                        <h5 style={{paddingBottom: '7px'}}>Adcionar individualmente</h5>
                        <p>Informações sobre o aluno.</p>
                    </Col>
                    <Col lg="16" xs="24">
                        <Card>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Nome do Aluno</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Input 
                                            value={studentState.student_name}
                                            onChange={(value) => { handleStudentState("student_name", value) }}
                                        />

                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>E-mail do Aluno</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Input 
                                            value={studentState.student_email}
                                            onChange={(value) => { handleStudentState("student_email", value) }}
                                        />

                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>CPF do Aluno <small>(somente números)</small></p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Input 
                                            value={studentState.student_taxid}
                                            onChange={(value) => { handleStudentState("student_taxid", value) }}
                                        />

                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Escola</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <SelectPicker
                                            style={{width: '100%'}}
                                            data={schoolList}
                                            labelKey="name"
                                            valueKey="_id"
                                            searchable={true}
                                            onChange={(value, event) => { handleStudentState("school", value) }}
                                            value={studentState.school}
                                            cleanable={false}
                                            preventOverflow={true}
                                            appearance="default"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Curso</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <SelectPicker
                                            style={{width: '100%'}}
                                            data={courseList}
                                            searchable={true}
                                            onChange={(value, event) => { handleStudentState("course", value) }}
                                            value={studentState.course}
                                            cleanable={false}
                                            preventOverflow={true}
                                            appearance="default"
                                            labelKey="name"
                                            valueKey="_id"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Turma</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <SelectPicker
                                            style={{width: '100%'}}
                                            data={classList}
                                            labelKey="displayName"
                                            valueKey="_id"
                                            searchable={true}
                                            onChange={(value, event) => { handleStudentState("class", value) }}
                                            value={studentState.class}
                                            cleanable={false}
                                            preventOverflow={true}
                                            appearance="default"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <Row style={{marginTop: '15px'}}>
                            <Col lg={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button 
                                    appearance="primary" 
                                    loading={""} 
                                    onClick={() => addStudent()}
                                    disabled={!isFormValid()}
                                >
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* <Row style={{marginTop: '25px'}}>
                    <Col lg="8" xs="24">
                        <h5 style={{paddingBottom: '7px'}}>Adcionar lista por planilha</h5>
                        <p>Informações sobre o aluno.</p>
                    </Col>
                    <Col lg="16" xs="24">
                        <Card>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Escola</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <SelectPicker
                                            style={{width: '100%'}}
                                            data={schoolList}
                                            labelKey="name"
                                            valueKey="_id"
                                            searchable={true}
                                            onChange={(value, event) => { handleStudentListState("school", value) }}
                                            value={studentListState.school}
                                            cleanable={false}
                                            preventOverflow={true}
                                            appearance="default"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Curso</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <SelectPicker
                                            style={{width: '100%'}}
                                            data={courseList}
                                            searchable={true}
                                            onChange={(value, event) => { handleStudentListState("course", value) }}
                                            value={studentListState.course}
                                            cleanable={false}
                                            preventOverflow={true}
                                            appearance="default"
                                            labelKey="name"
                                            valueKey="_id"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Turma</p>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <SelectPicker
                                            style={{width: '100%'}}
                                            data={classList}
                                            labelKey="displayName"
                                            valueKey="_id"
                                            searchable={true}
                                            onChange={(value, event) => { handleStudentListState("class", value) }}
                                            value={studentListState.class}
                                            cleanable={false}
                                            preventOverflow={true}
                                            appearance="default"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Button appearance="primary" onClick={() => document.querySelector('input[type=file]').click()} style={{marginRight: '15px'}}><Icon icon="upload"/> Selecionar Arquivo</Button>
                            </Row>
                        </Card>
                        <Row style={{marginTop: '15px'}}>
                            <Col lg={12}>
                                <Row style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <Button 
                                        //loading={isLoading} 
                                        appearance="primary"
                                        onClick={()=>openInNewTab(modelSheetLink)}
                                    >
                                        <Icon icon="cloud-download"/> Baixar Planilha Modelo
                                    </Button>
                                </Row>
                            </Col>
                            <Col lg={12}>
                                <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button 
                                        appearance="primary" 
                                        loading={""} 
                                        onClick={() => addStudent()}
                                        disabled={!isFormListValid()}
                                    >
                                        Adicionar
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
            </> : null
        }

        <GenericDrawer
            handleApply={() => { 
                if (tab === 1) {
                    getStudentList(1, drawerState?.filter?.data);
                } else if (tab === 2) {
                    getSchoolPaginatedList(1, drawerState?.filter?.data);
                } 
                closeDrawer('filter');
            }}
            modalState={drawerState?.filter?.open}
            closeModal={() => closeDrawer('filter')}
            title="Filtros"
        >
            {
             tab === 1 ? <>
                    <Row>
                        <Col lg={24} style={{textAlign: 'right'}}>
                            <Button appearance="link" onClick={() => {
                                clearDrawerData('filter')
                                getStudentList(1, {});
                            }}>Limpar filtros <Icon icon="close" /></Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Escola</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <CheckPicker
                                    style={{width: '100%'}}
                                    data={schoolList}
                                    labelKey="name"
                                    valueKey="_id"
                                    searchable={true}
                                    onChange={(value, event) => { handleDrawerChange("school", value) }}
                                    value={drawerState.filter.data.school}
                                    cleanable={false}
                                    preventOverflow={true}
                                    appearance="default"
                                />
                            </div>
                        </Col>
                    </Row>
                    
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Curso</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <CheckPicker
                                    style={{width: '100%'}}
                                    data={courseList}
                                    searchable={true}
                                    onChange={(value, event) => { handleDrawerChange("course", value) }}
                                    value={drawerState.filter.data.course}
                                    cleanable={false}
                                    preventOverflow={true}
                                    appearance="default"
                                    labelKey="name"
                                    valueKey="_id"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Nome do Aluno</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input 
                                    value={drawerState.filter.data?.student_name}
                                    onChange={(value) => { handleDrawerChange("student_name", value) }}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>E-mail do Aluno</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input 
                                    value={drawerState.filter.data?.student_email}
                                    onChange={(value) => { handleDrawerChange("student_email", value) }}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>CPF do Aluno <small>(somente números)</small></p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input 
                                    value={drawerState.filter.data?.student_taxid}
                                    onChange={(value) => { handleDrawerChange("student_taxid", value) }}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Turma</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <CheckPicker
                                    style={{width: '100%'}}
                                    data={classList}
                                    labelKey="displayName"
                                    valueKey="_id"
                                    searchable={true}
                                    onChange={(value, event) => { handleDrawerChange("class", value) }}
                                    value={drawerState.filter.data.class}
                                    cleanable={false}
                                    preventOverflow={true}
                                    appearance="default"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Status</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <SelectPicker 
                                    onChange={(value) => { handleDrawerChange("active", value) }}
                                    // value={drawerState?.data.events}
                                    value={drawerState.filter.data.status}
                                    preventOverflow={true}
                                    searchable={false}
                                    data={[
                                        {
                                            label: "Ativo",
                                            value: true,
                                        },
                                        {
                                            label: "Inativo",
                                            value: false,
                                        },
                                    ]} 
                                style={{ width: '100%' }}  />
                            </div>
                        </Col>
                    </Row>
                </> : null
            }
            {
                tab === 2 ? <>
                     <Row>
                        <Col lg={24} style={{textAlign: 'right'}}>
                            <Button appearance="link" onClick={() => {
                                clearDrawerData('filter')
                                getSchoolPaginatedList(1, {});
                            }}>Limpar filtros <Icon icon="close" /></Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Escola</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <CheckPicker
                                    style={{width: '100%'}}
                                    data={schoolList}
                                    labelKey="name"
                                    valueKey="_id"
                                    searchable={true}
                                    onChange={(value, event) => { handleDrawerChange("school", value) }}
                                    value={drawerState.filter.data.school}
                                    cleanable={false}
                                    preventOverflow={true}
                                    appearance="default"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Turma</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <CheckPicker
                                    style={{width: '100%'}}
                                    data={classList}
                                    labelKey="displayName"
                                    valueKey="_id"
                                    searchable={true}
                                    onChange={(value, event) => { handleDrawerChange("class", value) }}
                                    value={drawerState.filter.data.class}
                                    cleanable={false}
                                    preventOverflow={true}
                                    appearance="default"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Nome do Aluno</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input 
                                    value={drawerState.filter.data?.student_name}
                                    onChange={(value) => { handleDrawerChange("student_name", value) }}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>E-mail do Aluno</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input 
                                    value={drawerState.filter.data?.student_email}
                                    onChange={(value) => { handleDrawerChange("student_email", value) }}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>CPF do Aluno <small>(somente números)</small></p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input 
                                    value={drawerState.filter.data?.student_taxid}
                                    onChange={(value) => { handleDrawerChange("student_taxid", value) }}
                                />

                            </div>
                        </Col>
                    </Row>
                </> : null
            }
        </GenericDrawer>

        <GenericModal 
            closeLabel="Fechar"
            color="green"
            modalState={reportModalState?.open} 
            closeModal={() => closeReportModal()} 
            handleApply={async () => {await requestReport(); closeReportModal()}}
            applyLabel={<><Icon icon="flag"/> Solicitar relatório</>}
            title="Solicitar relatório"
        >
            <Row>
                <Col lg={24}>
                    <Row>
                        <Col lg={24}>
                        <Message
                            showIcon
                            type="info"
                            description={
                                <p>
                                    O relatório solicitado será gerado a partir dos filtros que você selecionar na pesquisa.<br /><br />Em alguns minutos um e-mail notificando da conclusão será enviado para você.<br /><br />
                                    Relatórios são excluídos automaticamente após 7 dias.
                                </p>
                            }
                            />

                            </Col>
                    </Row>
                    <hr></hr>
                </Col>
            </Row>
        </GenericModal>


    <Modal size="lg"
        show={modalInterface}
        onHide={() => closeInterfaceModal()}
    >
        <Modal.Header>
            <Modal.Title>{`Gestão do aluno: `}{userModal?.student?.name || 'Carregando...'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row style={{marginTop: '25px'}}>
                <Col lg={24}><h6>Curso: {commentsModal?.course?.name || "Carregando..."}</h6></Col>
            </Row>

            <Row className="only-desk">
                <Row style={{margin: '20px 5px', padding: '15px 5px', background: 'rgba(255, 255, 255, 0.6)', display: 'flex', flexDirection: 'row'}}>
                    <Row style={{width: '100%'}}>
                        <Button appearance={tabModal === 1 ? 'primary' : 'subtle'} onClick={() => handleTabModalClick(1)}>Progresso</Button>
                        <Button appearance={tabModal === 2 ? 'primary' : 'subtle'} onClick={() => handleTabModalClick(2)}>Controle de Turma</Button>
                    </Row>
                </Row>
            </Row>

            {
                tabModal === 1 ? <>
                    <Row className="table-container" style={{marginLeft: "10px"}}>
                        <Row className="table-webhook">
                            <Row style={{marginTop:"25px", background:'white',display: 'flex', flexDirection: 'row', padding:"10px 15px"}} className="list-responsive">
                                <Row className="rs-col-md-8 rs-col-xs-8">
                                    {<h6>Modulos</h6>}
                                </Row>
                                <Row className="rs-col-md-8 rs-col-xs-8">
                                    {<h6>PROGRESSO</h6>}
                                </Row>
                            </Row>
                        </Row>
                    </Row>
            
                {      
                    commentsModal?.modules?.length != 0 ? <>
                    {
                        commentsModal?.modules?.map(module =>{
                            return <>
                            <Panel                                                        
                            style={{ background: "#d2d6dc30", paddingBottom: '10px', marginBottom: '.7em'}}
                            header={
                            <Row key={module?._id} style={{fontSize: '0.9em'}}>
                                <Row className="rs-col-md-8 rs-col-xs-8">
                                    {<h6>{module?.name}</h6>}
                                </Row>
                                <Row className="rs-col-md-8 rs-col-xs-8">
                                    <div style={{position: 'absolute',  minWidth: '3%', width: '90%', left: '5px', bottom: '-20px', display: 'flex', alignItems: 'center'}}>
                                        <div className="progress-bar" style={{marginLeft: '15px', minWidth: '3%', width: `${progressModal?.modulesProgress?.find(_module => _module.module_id == module?._id)?.donePercent?.toFixed()}%` , height: '5px', borderRadius: '3px'}}></div> 
                                        <span style={{fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>
                                            {
                                                (progressModal?.modulesProgress?.find(_module => _module.module_id == module?._id)?.donePercent ?? 0).toFixed(0)
                                            }%
                                        </span>
                                    </div>
                                </Row>
                                <Row className="rs-col-md-8 rs-col-xs-8">
                                    
                                </Row>
                            </Row>}
                            collapsible>
                            {  
                                module.lessons.length != 0 ? <>
                                {                                           
                                    module.lessons.map(lesson => {
                                        const moduleProgress = progressModal?.modulesProgress
                                            ?.find(_module => _module?.module_id === module?._id);

                                        const lessonProgress = moduleProgress?.progress
                                            ?.find(_item => _item?.lesson === lesson?.childrenId) ?? 0;

                                        let status = "Vazio"

                                        if(exerciceSessions[lesson._id] && exerciceSessions[lesson._id]?.length > 0){    
                                            status = exerciceSessions[lesson._id][exerciceSessions[lesson._id].length - 1]?.status
                                        }

                                        return <Panel   
                                            key={lesson?._id}                                                     
                                            style={{ background: "white", paddingBottom: '10px', marginBottom: '.7em'}}
                                            header={
                                                <Row key={lesson?._id} style={{fontSize: '0.9em'}}>
                                            <Col className="rs-col-md-8 rs-col-xs-8">
                                                {<h6>{lesson?.name}</h6>}
                                            </Col>
                                            <Col className="rs-col-md-8 rs-col-xs-8">
                                                {
                                                    lesson.type !== "exercice" ? <>
                                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '15px'}}>
                                                            <div><Icon icon="youtube-play" size="2x" style={{color: '#3b82f6'}} /></div>
                                                            <div style={{  minWidth: '3%', width: '90%', left: '5px', bottom: '-20px', display: 'flex', alignItems: 'center'}}>
                                                                <div className="progress-bar" style={{marginLeft: '15px', minWidth: '3%', width: `${((lessonProgress?.watchTime * 100) / lessonProgress?.totalTime).toFixed()}%` , height: '5px', borderRadius: '3px'}}></div> 
                                                                <span style={{fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>
                                                                    {
                                                                        ((lessonProgress?.watchTime * 100) / lessonProgress?.totalTime || 0).toFixed(0)
                                                                    }%
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '15px'}}>
                                                        <div><Icon icon="edit" style={{color: '#3b82f6'}} /></div>
                                                        <div style={{marginLeft: '25px'}}>{statusExercice(status)}</div>
                                                    </div>                                           
                                                }
                                            </Col>
                                            <Col className="rs-col-md-8 rs-col-xs-8">
                                                
                                            </Col>
                                        </Row>}
                                        collapsible>
                                            {
                                                lesson.type === 'exercice' && (
                                                    <>
                                                        <Row style={{marginTop: '25px'}}>
                                                            <Col lg={24}>
                                                                <h5>Histórico de tentativas</h5>
                                                            </Col>
                                                        </Row>
                                                        <div className="table-container" style={{marginTop: '25px'}}>
                                                            <div className="table">
                                                                {
                                                                    exerciceSessions[lesson._id]?.length ? 
                                                                    <div style={{background: "#d2d6dc30",display: 'flex', flexDirection: 'row', marginBottom: '.7em'}} className="list-responsive theme-table">
                                                                        <div className="rs-col-md-2 rs-col-xs-2">#</div>
                                                                        <div className="rs-col-md-6 rs-col-xs-6">DATA DE INÍCIO</div>
                                                                        <div className="rs-col-md-4 rs-col-xs-4" style={{textAlign: 'center'}}>RESPOSTAS</div>
                                                                        <div className="rs-col-md-4 rs-col-xs-4" style={{textAlign: 'center'}}>ACERTOS</div>
                                                                        <div className="rs-col-md-4 rs-col-xs-4"></div>
                                                                        <div className="rs-col-md-4 rs-col-xs-4">STATUS</div>
                                                                    </div> : null
                                                                }

                                                                {   
                                                                    !exerciceSessions[lesson._id]?.length ? <>
                                                                        <Row style={{marginTop: '25px'}}>
                                                                            <Col lg={24}>
                                                                                    <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhum exercício realizado</h5>
                                                                            </Col>
                                                                        </Row>
                                                                    </> : null
                                                                }

                                                                {
                                                                    exerciceSessions[lesson._id]?.map((session, index) => {
                                                                        return <Panel   
                                                                        key={lesson?._id}                                                     
                                                                        style={{ background: "#d2d6dc30", paddingBottom: '10px', marginBottom: '.7em'}}
                                                                        header={
                                                                            <div>
                                                                                <div className="rs-col-md-2 rs-col-xs-2">{index + 1}</div>
                                                                                <div className="rs-col-md-6 rs-col-xs-6">{parseDateTime(session?.createdAt)}</div>

                                                                                <div className="rs-col-md-4 rs-col-xs-4" style={{textAlign: 'center'}}>{session?.answers ? Object.keys(session?.answers).length : 0} / {questions?.length || 0}</div>

                                                                                <div className="rs-col-md-4 rs-col-xs-4" style={{textAlign: 'center'}}>{(session?.status === "finished" || session?.status === "expired") ? (session?.answers ? Object.values(session?.answers).filter(answer => answer.correct === true).length : 0) : '-'} / {questions?.length || 0}</div>

                                                                                <div className="rs-col-md-4 rs-col-xs-4"></div>
                                                                                <div className="rs-col-md-4 rs-col-xs-4">{statusExercice(session?.status)}</div>
                                                                            </div>}
                                                                        collapsible>
                                                                            
                                                                            {                                                  
                                                                                exerciceSessions[lesson._id][0]?.exercice?.questions?.map((question, index) => {
                                                                                    const correctAnswer = question.answers.find(answer => answer.id === question.correctAnswerId);

                                                                                    const idResposta = session.answers[question.id]

                                                                                    const resposta = question.answers.find(answer => answer.id === idResposta?.answer);
                            
                                                                                    return <>
                                                                                        <Row style={{marginTop: '25px'}}>
                                                                                            <Col lg={24}>
                                                                                                <p><strong>Pergunta {index + 1}: </strong>{question?.text}</p>
                                                                                                <p><strong>Resposta correta: </strong> {correctAnswer ? correctAnswer?.text : "Resposta não encontrada"}</p>
                                                                                                <p><strong>Resposta do aluno: </strong> {resposta?.text} </p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </>
                                                                                })                                                 
                                                                            }
                                                                            
                                                                        </Panel>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            { 
                                                lesson.type !== 'exercice' && (
                                                    <>
                                                        <Row className="table-container">
                                                            <Row className="table-webhook">
                                                                <Row style={{marginTop:"25px", background:'white',display: 'flex', flexDirection: 'row', padding:"10px 15px"}} className="list-responsive">
                                                                    <Row className="rs-col-md-8 rs-col-xs-8">
                                                                        Comentários
                                                                    </Row>
                                                                    <Row className="rs-col-md-5 rs-col-xs-5">
                                                                        
                                                                    </Row>
                                                                    <Row className="rs-col-md-9 rs-col-xs-9">
                                                                        
                                                                    </Row>
                                                                    <Row className="rs-col-md-5 rs-col-xs-5">
                                                                    </Row>
                                                                </Row>
                                                            </Row>
                                                        </Row>
                                                        {
                                                            lesson.comments.length != 0 ? <>
                                                                {
                                                                    lesson.comments.map(comment =>{
                                                                        return<>
                                                                        <Row>
                                                                            <Col lg={24}>
                                                                                <Card style={{background: "#d2d6dc30"}}>
                                                                                    <Row style={{ marginTop: '15px', color: 'rgba("0,0,0,0.4")'}}>{comment.text}</Row>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                        </>
                                                                    })
                                                                }
                                                            </> :
                                                            <Row>
                                                                <Col lg={24}>
                                                                    <Card>
                                                                        <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Não contem comentários</h5>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }                                              
                                                    </>
                                                )
                                            }
                                        </Panel>
                                    })                      
                                }
                                </>:
                                <Row>
                                    <Col lg={24}>
                                        <Card>
                                            <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Não contem lições</h5>
                                        </Card>
                                    </Col>
                                </Row>
                            }  
                            </Panel>
                            
                            </>
                        })
                    }
                    </> :
                    <Row>
                        <Col lg={24}>
                            <Card>
                                <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Não contem modulos</h5>
                            </Card>
                        </Col>
                    </Row>
                }
                </> : null
            }
            {
                tabModal === 2 ? <>
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>Gerenciar Turma do Curso</h5>
                            <p>Configure a turma do aluno para o curso ao qual ele está matriculado.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <Card>
                                <Row style={{marginTop: '15px'}}>
                                    <Col lg={14}>
                                        <p>Turma</p>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <SelectPicker
                                                style={{width: '100%'}}
                                                data={classListModal}
                                                labelKey="displayName"
                                                valueKey="_id"
                                                searchable={true}
                                                onChange={(value, event) => { setUpdateClassModal( value) }}
                                                value={drawerState.filter.data.class}
                                                cleanable={false}
                                                preventOverflow={true}
                                                appearance="default"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </> : null
            }   

        </Modal.Body>
        <Modal.Footer>
            <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col lg={12}>
                    <div style={{padding: '15px 5px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        {
                            tabModal === 2 ? <>
                                <Button 
                                    color="red" 
                                    appearance='ghost'
                                    disabled = {removeClassModal}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openRemoveModal( userModal?.student, classModal, false, commentsModal?.course?._id );
                                    }} 
                                >
                                    {<Icon icon="minus-square" style={{marginRight: '5px'}} />}
                                    {`Remover turma`}
                                </Button>
                            </> : null
                        }
                    </div>
                </Col>
                {/* leo */}
                <Col lg={12}>
                <div style={{ padding: '15px 5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {
                        tabModal === 2 ? <>
                            <Button
                                onClick={() => handleUpdateStudentClass(userModal?.student?._id, updateClassModal, commentsModal?.course?._id)}
                                appearance="primary"
                            >
                                {"Adicionar turma"}
                            </Button>
                        </> : null
                    }
                    <Button
                        onClick={() => closeInterfaceModal()}
                        appearance="subtle">
                        Cancelar
                    </Button>
                    </div>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>

    <Modal size="xs"
        show={modalRemoveState}
        onHide={closeRemoveModal}
    >
        <Modal.Header>
            <Modal.Title>{`Remover: ${removeStudentModal.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                modalInterface ? <>
                    <p>Tem certeza de que deseja remover este curso do aluno nesta turma?</p>
                </> :
                <div>
                    <p>Deseja remover o aluno da turma?</p>
                    <p>Todos os cursos do aluno serão removidos desta turma.</p>
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={() => handleRemoveStudentClass(removeStudentModal._id, removeClassIdtModal, removeStateAllCourse, removeCourseIdtModal)}
                color="red">
                {<Icon icon="minus-square" style={{ marginRight: '5px' }} />}
                {"Remover"}
            </Button>
            <Button
                onClick={closeRemoveModal}
                appearance="subtle">
                Cancelar
            </Button>
        </Modal.Footer>
    </Modal>

    <Modal size="xs"
        show={modalAddStudent}
        onHide={() => closeModalAddStudent()}
    >
        <Modal.Header>
            <Modal.Title>{`Adicione um aluno`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col lg={24}>
                    <p>Nome do Aluno</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                            value={studentState.student_name}
                            onChange={(value) => { handleStudentState("student_name", value) }}
                        />

                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>E-mail do Aluno</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                            value={studentState.student_email}
                            onChange={(value) => { handleStudentState("student_email", value) }}
                        />

                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>CPF do Aluno <small>(somente números)</small></p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                            value={studentState.student_taxid}
                            onChange={(value) => { handleStudentState("student_taxid", value) }}
                        />

                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Escola</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SelectPicker
                            style={{width: '100%'}}
                            data={schoolList}
                            labelKey="name"
                            valueKey="_id"
                            searchable={true}
                            onChange={(value, event) => { handleStudentState("school", value) }}
                            value={studentState.school}
                            cleanable={false}
                            preventOverflow={true}
                            appearance="default"
                        />
                    </div>
                </Col>
            </Row>
            
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Curso</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SelectPicker
                            style={{width: '100%'}}
                            data={courseList}
                            searchable={true}
                            onChange={(value, event) => { handleStudentState("course", value) }}
                            value={studentState.course}
                            cleanable={false}
                            preventOverflow={true}
                            appearance="default"
                            labelKey="name"
                            valueKey="_id"
                        />
                    </div>
                </Col>
            </Row>
            {/* leo */}
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Tipo de acesso</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SelectPicker
                            style={{width: '100%'}}
                            data={accessList}
                            searchable={true}
                            onChange={(value, event) => { handleStudentState("typeOfAccess", value) }}
                            value={studentState.typeOfAccess}
                            cleanable={false}
                            preventOverflow={true}
                            appearance="default"
                            />
                    </div>
                </Col>
            </Row>
            <hr></hr>
            <p><strong>Campos opcionais</strong></p>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Turma</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SelectPicker
                            style={{width: '100%'}}
                            data={classList}
                            labelKey="displayName"
                            valueKey="_id"
                            searchable={true}
                            onChange={(value, event) => { handleStudentState("class", value) }}
                            value={studentState.class}
                            cleanable={false}
                            preventOverflow={true}
                            appearance="default"
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Data de expiração</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <DatePicker
                            onChange={(value) => { handleStudentState("expirationDate", value) }}
                            appearance="default"
                            preventOverflow={true}
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(date) => date < new Date()}
                            ranges={[
                                {
                                    label: 'Próximos 7 dias',
                                    value: setEndOfDay(addDays(new Date(), 7))
                                },
                                {
                                    label: 'Próximos 30 dias',
                                    value: setEndOfDay(addDays(new Date(), 30))
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button 
                appearance="primary" 
                loading={""} 
                onClick={() => addStudent()}
                disabled={!isFormValid()}
            >
                Adicionar
            </Button>
            <Button
                onClick={() => closeModalAddStudent()}
                appearance="subtle">
                Cancelar
            </Button>
        </Modal.Footer>
    </Modal>
</>
}

export default StudentsPage